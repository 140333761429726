@import '../../../../../../../styles/main';

.preimagine-fds-video-content-main {
    .preimagine-fds-video-content {
        position: relative;
        .video-js {
            width: 100%;
            object-fit: fill;
            padding-top: 56.25%;
            overflow: hidden;
            border-radius: pToR(8);
            height: auto;
            .vjs-big-play-button {
                margin: 0;
                position: absolute;
                top: 50%;
                left: 50%;
                -ms-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
                background-color: transparent !important;
                background-image: url('../../../../../../../assets/preimagine-play.svg');
                background-repeat: no-repeat;
                background-size: contain;
                border-radius: 0;
                padding: 2px;

                width: pToR(64);
                height: pToR(64);
                @include desktop {
                    width: pToR(120);
                    height: pToR(120);
                }
                &:hover {
                    width: 7rem;
                    height: 7rem;
                }
                .vjs-icon-placeholder {
                    display: none;
                }
            }
        }
    }
}
