.brand-lincoln {
    .horizontal-card {
        display: flex;
        flex-direction: column;
        position: relative;
        justify-content: center;
        margin: 0 auto;
        width: 100%;
        max-width: 520px;

        font-family: $proxima-nova-regular;
        font-size: 1rem;
        letter-spacing: 2px;
        line-height: 26px;
        color: $brand-secondary;
        text-align: left;
        background-color: #ffffff;
        box-shadow: 0 20px 20px 0 rgba(0, 0, 0, 0.1),
            0 10px 10px 0 rgba(0, 0, 0, 0.1);

        @include desktop {
            flex-direction: row;
            max-width: 1242px;
        }

        .horizontal-card__image {
            width: 100%;
            height: 264px;
            background-size: cover;
            background-position: center center;
            background-origin: border-box;
            @include desktop {
                height: auto;
                width: 50%;
            }
        }

        .horizontal-card__content-wrapper {
            padding: 0 32px;
            width: 100%;
            min-height: 441px;
            position: relative;
            @include tablet {
                min-height: 341px;
            }
            @include md-desktop {
                width: 50%;
            }
            @include xl-desktop {
                padding: 0 160px 0 32px;
            }
            .horizontal-card__title {
                margin-top: 28px;
                line-height: 1.625rem;
                letter-spacing: 2px;
                font-size: 1rem;
                text-transform: uppercase;
                margin-bottom: 16px;
                padding-bottom: 5px;
                border-bottom: 1px solid $brand-secondary;
                text-align: left;
            }
            .horizontal-card__divider {
                display: none;
            }
            .horizontal-card__subtitle {
                margin: 12px 0 0 0;
                font-size: 2rem;
                font-family: $proxima-nova-light;
                font-weight: 300;
                letter-spacing: 2px;
                line-height: 42px;
                text-transform: uppercase;
                text-align: left;
            }

            .horizontal-card__subtitle_h2 {
                margin: 12px 0 0 0;
                font-size: 2rem;
                font-family: $proxima-nova-light;
                font-weight: 300;
                letter-spacing: 2px;
                line-height: 42px;
                text-transform: uppercase;
                text-align: left;
                padding-top: 15px;
            }

            .horizontal-card__body {
                font-family: $proxima-nova-regular;
                font-size: 1.125rem;
                letter-spacing: 0px;
                line-height: 24px;
                margin: 20px 0;
                font-weight: 400;
            }

            .horizontal-card__primary-button {
                position: absolute;
                display: inline-flex;
                letter-spacing: 0px;
                bottom: 24px;
                margin: 60px 0 0 0;
                @include tablet {
                    bottom: 36px;
                }
            }
        }
    }
}
