.brand-ford {
    .popular-links-section {
        margin-top: 50px;
        max-height: 620px;
        max-width: 317px;
        @include desktop {
            position: sticky;
            top: 20%;
            right: 10%;
            height: 100%;
        }
        .related-categories,
        .popular-in-rewards {
            max-height: 285px;
            &__header {
                color: $fds-color-dk-gray;
                font-family: $f1-font-regular;
                line-height: 26px;
                font-size: 16px;
                margin-bottom: 10.5px;
            }
            &__item {
                margin-top: 20px;
                a {
                    text-decoration: none;
                    color: $fds-color-primary;
                    font-family: $f1-font-regular;
                    font-size: 18px;
                    line-height: 28px;
                }
            }
        }
        .popular-in-rewards {
            margin-top: 50px;
        }
        hr {
            box-shadow: none;
        }
        .popular-in-rewards__item a {
            &:hover {
                text-decoration: underline;
                color: $light-blue;
                cursor: pointer;
            }
        }
        .related-categories__item a {
            &:hover {
                text-decoration: underline;
                color: $light-blue;
                cursor: pointer;
            }
        }
    }
}
