.brand-lincoln {
    .component-box-5050 {
        font-family: $proxima-nova-light;
        color: $fds-color-dk-gray;
        margin: 60px 0;
        width: 100%;

        @include fds-medium {
            display: grid;
            margin: 100px 0;
            position: relative;
        }

        .component-box-5050-image {
            background-position: center center;
            background-size: cover;
            text-align: center;
            background-repeat: no-repeat;
            height: 450px;

            @include fds-small {
                height: 210px;
            }
            @include tablet {
                height: 268px;
            }
            @include fds-medium {
                height: 395px;
            }
            @include fds-large {
                height: 499px;
            }
        }

        .component-box-5050-body {
            background: $white;
            padding: 40px 90px 40px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            box-sizing: border-box;

            @include fds-x-small {
                padding: 32px 16px;
            }
            @include fds-small {
                padding: 32px 100px;
            }

            @include fds-medium {
                background: unset;
                height: 395px;
                padding-right: 88px;
                width: 50%;
                justify-content: center;
                align-items: flex-start;
                text-align: left;
                margin: -395px 0 0 auto;
            }

            @include md-desktop {
                padding-right: unset;
            }

            @include fds-large {
                height: 499px;
                margin-top: -499px;
            }

            .component-box-5050-title {
                display: block;
                text-transform: uppercase;
                text-align: left;
                @include text(
                    17,
                    2px,
                    27px,
                    $brand-secondary,
                    $proxima-nova-regular
                );

                @include fds-small {
                    max-width: 423px;
                }

                @include fds-medium {
                    margin-top: -20px;
                }
            }

            .component-box-5050-subtitle {
                @include text(
                    32,
                    2px,
                    42px,
                    $primary-graphite,
                    $proxima-nova-regular
                );
                font-weight: 300;
                text-transform: uppercase;
                text-align: left;
                @include md-desktop {
                    padding-right: 88px;
                }
            }

            .component-box-5050-description {
                font-size: pToR(18);
                margin-bottom: 30px;
                letter-spacing: 0;
                line-height: 26px;
                max-width: 476px;
                color: $primary-graphite;
            }
            .component-box-5050-link {
                box-sizing: border-box;
                border: 2px solid $brand-secondary;
                font-size: pToR(16);
                letter-spacing: 0;
                line-height: pToR(20);
            }
        }
    }
}
