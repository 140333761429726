.brand-lincoln {
    .latest-articles {
        box-sizing: border-box;

        .latest-articles-divider {
            height: 1px;
            border: 1px solid $border-grey;
            box-shadow: none;
            @include desktop {
                height: 2px;
            }
        }

        .latest-articles-header {
            @include text(16, 2px, 26px, $brand-secondary, $proxima-nova-light);
            text-transform: uppercase;
        }

        .latest-articles-container {
            margin-top: 35px;
            @include desktop {
                margin-top: 52px;
            }

            .latest-article {
                display: flex;
                flex-direction: column-reverse;
                margin-bottom: 40px;
                text-decoration: none;

                .article-info {
                    .title {
                        @include text(
                            24,
                            2px,
                            32px,
                            $brand-secondary,
                            $proxima-nova-regular
                        );
                        font-weight: 300;
                        text-transform: uppercase;
                        text-decoration: none;
                        &:hover {
                            text-decoration: underline $brand-border 2px;
                        }
                    }

                    .description {
                        @include text(
                            16,
                            0,
                            24px,
                            $brand-secondary,
                            $proxima-nova-regular
                        );
                        display: none;
                    }
                }

                .article-asset {
                    margin-bottom: 15px;
                    img {
                        min-height: 180px;
                        width: 100%;
                    }
                }

                @include tablet {
                    flex-direction: row;
                    margin-bottom: 52px;
                    max-width: 819px;
                    .article-info {
                        .description {
                            display: block;
                        }
                    }
                    .article-asset {
                        margin-left: 25px;
                        margin-bottom: 0;
                        img {
                            min-height: 180px;
                            width: 378px;
                        }
                    }
                }

                @include desktop {
                    flex-direction: row;
                    margin-bottom: 52px;
                    .article-info {
                        .title {
                            @include text(
                                24,
                                3px,
                                34px,
                                $brand-secondary,
                                $proxima-nova-light
                            );
                        }
                        .description {
                            display: block;
                        }
                    }
                    .article-asset {
                        margin-right: 0;
                        margin-left: 75px;
                        margin-bottom: 0;
                        img {
                            height: auto;
                            width: 211px;
                        }
                    }
                }
            }
        }

        .latest-articles-load-more {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-top: 20px;

            .show-more {
                padding: 0;
            }

            .plus-circle {
                height: 14px;
                width: 14px;
                margin-left: 10px;
            }

            .page-count {
                @include text(
                    13,
                    0,
                    17px,
                    $brand-secondary,
                    $proxima-nova-regular
                );
                text-align: right;
            }
        }
    }
}
