.brand-ford {
    .service-and-offers-section,
    .vehicle-health-page-wrapper {
        .category-5050-carousel-container {
            padding-top: 0;
            padding-bottom: 45px;
            margin-top: 25px;
            margin-bottom: 0;
            @include tablet {
                margin-top: 0px;
            }

            .category-5050-carousel-heading {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin: 0px 16px;
                max-width: 90rem;
                border-bottom: 1px solid $border-grey;
                @include tablet {
                    margin: 0px 1.8em;
                }
            }
            .category-5050-carousel__title {
                font-size: 20px;
                font-family: $f1-font-light;
                color: $fds-color-dk-gray;
                line-height: 30px;
                text-transform: uppercase;
                text-align: left;
                margin-bottom: 0px;
                padding-top: 19px;
                padding-bottom: 8px;
            }

            .secondary-button {
                display: inline-block;
                padding-top: 13px;
                .secondary-button-text {
                    display: none;
                }
                .secondary-button-link {
                    padding: 0px;
                }
            }

            .category-5050-carousel__controls {
                .category-5050-carousel-button {
                    padding: 12px 10px;
                    .fds-arrow {
                        font-size: 22px;
                    }
                }
            }
            .category-5050-carousel__indicator {
                color: $fds-color-dk-gray;
            }
        }
    }
}
