.brand-ford {
    .component-box-5050 {
        font-family: $f1-font-light;
        color: $fds-color-dk-gray;
        margin: 60px 0;
        width: 100%;

        @include fds-medium {
            display: grid;
            margin: 100px 0;
            position: relative;
        }

        .component-box-5050-image {
            background-position: center center;
            background-size: cover;
            text-align: center;
            background-repeat: no-repeat;
            height: 450px;

            @include fds-small {
                height: 210px;
            }
            @include tablet {
                height: 268px;
            }
            @include fds-medium {
                height: 395px;
            }
            @include fds-large {
                height: 499px;
            }
        }

        .component-box-5050-body {
            background: $white;
            padding: 50px 90px 31px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            box-sizing: border-box;
            @include fds-x-small {
                padding: 32px 16px;
            }
            @include fds-small {
                padding: 32px 100px;
            }

            @include fds-medium {
                background: unset;
                height: 395px;
                padding-right: 88px;
                width: 50%;
                justify-content: center;
                align-items: flex-start;
                text-align: left;
                margin: -395px 0 0 auto;
            }

            @include md-desktop {
                padding-right: 20px;
            }

            @include fds-large {
                height: 499px;
                margin-top: -499px;
            }

            .component-box-5050-title {
                font-size: 1rem;
                display: block;
                font-family: $f1-font-light;
                line-height: 26px;
                text-transform: uppercase;

                @include fds-small {
                    max-width: 423px;
                }

                @include fds-medium {
                    margin-top: -20px;
                }
            }

            .component-box-5050-subtitle {
                font-family: $f1-font-light;
                font-size: 3rem;
                line-height: 58px;
                text-transform: uppercase;
                @include md-desktop {
                    padding-right: 88px;
                    text-align: left;
                }
            }

            .component-box-5050-description {
                font-size: 1rem;
                margin-bottom: 30px;
                line-height: 26px;
                max-width: 476px;
            }
        }
    }
}
