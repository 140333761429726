.brand-ford {
    .latest-articles {
        box-sizing: border-box;

        .latest-articles-divider {
            height: 1px;
            border: 1px solid $border-grey;
            box-shadow: none;
            @include desktop {
                height: 2px;
            }
        }

        .latest-articles-header {
            @include text(16, 2px, 26px, $fds-color-dk-gray, $f1-font-light);
            text-transform: uppercase;
        }

        .latest-articles-container {
            margin-top: 35px;
            @include desktop {
                margin-top: 52px;
            }

            .latest-article {
                display: flex;
                flex-direction: column-reverse;
                margin-bottom: 40px;
                text-decoration: none;

                .article-info {
                    .title {
                        @include text(
                            20,
                            3px,
                            30px,
                            $fds-color-primary,
                            $f1-font-light
                        );
                        text-transform: uppercase;
                        text-decoration: none;
                        &:hover {
                            color: $light-blue;
                            text-decoration: underline;
                        }
                    }

                    .description {
                        @include text(
                            16,
                            1px,
                            26px,
                            $fds-color-dk-gray,
                            $f1-font-regular
                        );
                        display: none;
                    }
                }

                .article-asset {
                    margin-bottom: 15px;
                    img {
                        min-height: 180px;
                        max-height: 285px;
                        width: 100%;
                    }
                }

                @include tablet {
                    flex-direction: row;
                    margin-bottom: 52px;
                    max-width: 819px;
                    .article-info {
                        .description {
                            display: block;
                        }
                    }
                    .article-asset {
                        margin-left: 25px;
                        margin-bottom: 0;
                        img {
                            min-height: 180px;
                            width: 378px;
                        }
                    }
                }

                @include desktop {
                    flex-direction: row;
                    margin-bottom: 52px;
                    .article-info {
                        .title {
                            @include text(
                                16,
                                3px,
                                34px,
                                $fds-color-primary,
                                $f1-font-light
                            );
                        }
                        .description {
                            display: block;
                        }
                    }
                    .article-asset {
                        margin-right: 0;
                        margin-left: 75px;
                        margin-bottom: 0;
                        img {
                            min-height: 164px;
                            max-height: 180px;
                            width: 211px;
                        }
                    }
                }
            }
        }

        .latest-articles-load-more {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-top: 20px;

            .show-more {
                padding: 0;
            }

            .plus-circle {
                height: 14px;
                width: 14px;
                margin-left: 10px;
            }

            .page-count {
                color: $fds-color-dk-gray;
                font-family: $f1-font-regular;
                font-size: 11px;
                line-height: 21px;
                text-align: right;
            }
        }
    }
    .latest-articles.preimagine {
        .latest-articles-header {
            color: $dyf-color-darkgrey;
            font-family: $f1-font-regular;
            text-transform: unset;
            font-size: pToR(32);
            line-height: pToR(40);
            @include tablet {
                font-size: pToR(40);
                line-height: pToR(48);
            }
        }
        hr {
            border-bottom: none;
            &:first-of-type {
                display: none;
            }
        }
        .latest-articles-container {
            margin: 32px 0 32px 0;
            .latest-article {
                margin-top: 29px;
                margin-bottom: unset;
                @include tablet {
                    flex-direction: row-reverse;
                }
                .article-info {
                    h3 {
                        margin-bottom: 0;
                        @include tablet {
                            margin-bottom: 13px;
                            margin-top: 7px;
                        }
                    }
                    .title {
                        text-transform: unset;
                        h3 {
                            font-size: pToR(24);
                            line-height: pToR(32);
                            color: $fm-breadcrumb-blue;
                            font-family: $f1-font-medium;
                            margin-bottom: 0;
                            @include tablet {
                                margin-bottom: 13px;
                                margin-top: 7px;
                            }
                        }
                    }
                    .description {
                        color: $dyf-color-darkgrey;
                        font-family: $f1-font-regular;
                        line-height: 24px;
                        letter-spacing: unset;
                    }
                }
                .article-asset {
                    margin-bottom: 24px;
                    img {
                        min-height: unset;
                        max-height: 220px;
                        border-radius: 8px;
                    }
                    @include desktop {
                        margin: 0 19px 0 0;
                        img {
                            min-height: 180px;
                            border-radius: 8px;
                        }
                    }
                    @include tablet {
                        margin: 0 19px 0 0;
                    }
                }
            }
            .fm-card-fifty-fifty {
                background: $banner-lightgrey-background;
                border-radius: 8px;
                margin-top: 29px;
                @include desktop {
                    margin: 48px 0;
                }
                .image-display {
                    @include desktop {
                        height: 100%;
                    }
                    .display-card-image-wrapper {
                        .display-card-image {
                            @include desktop {
                                height: 100%;
                            }
                        }
                    }
                    .fm-card-text {
                        padding: 16px 21px;
                        @include desktop {
                            padding: 16px 32px;
                        }
                        .fm-card__headline {
                            color: $fm-text-grey;
                            margin-top: 10px;
                            line-height: pToR(40);
                            @include desktop {
                                margin-top: 30px;
                                font-size: pToR(40);
                            }
                        }
                        .fm-card__description {
                            color: $fm-text-grey;
                            padding: 12px 0 24px 0;
                            @include desktop {
                                padding: 12px 0 32px 0;
                            }
                        }
                    }
                }
            }
        }
        .latest-articles-load-more {
            margin-top: 17px;
            align-items: baseline;
            .show-more {
                color: $fm-breadcrumb-blue;
                font-family: $f1-font-bold;
                line-height: pToR(20);
                font-size: pToR(14);
            }
            .plus-circle {
                height: unset;
                width: unset;
                gap: 10px;
            }
            .page-count {
                color: $dyf-color-darkgrey;
                font-family: $f1-font-regular;
                line-height: pToR(16);
                font-size: pToR(12);
            }
            .secondary-button {
                span {
                    display: contents;
                }
            }
        }
    }
}
