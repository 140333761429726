.brand-lincoln {
    .banner {
        .top {
            background-position: center;
            background-size: cover;
            color: white;
            font-family: $proxima-nova-light;
            height: 28rem;
            @include desktop {
                min-height: 28rem !important;
            }

            @include tablet {
                min-height: 40rem;
            }

            .cta {
                text-align: center;
                padding: 0;
                max-width: pToR(700);
                margin: 0 auto;

                @include desktop {
                    max-width: 100%;
                }
            }
        }
        .no-bottom {
            min-height: 370px !important;
            background-size: cover;
            background-position: center;
            @include mobile {
                min-height: 410px !important;
            }
        }

        .bottom-container {
            position: relative;

            .bottom {
                position: relative;
                display: flex;
                justify-content: center;
            }
        }
    }
}
