.brand-ford {
    .sub-category-view-masthead-container {
        .sub-category-view-masthead {
            color: $dyf-color-darkgrey;
            padding: 16px 14px 0 18px;
            @include desktop {
                padding: 30px 56px 0 56px;
            }
            .sub-category-view-masthead__wrapper {
                font-family: $f1-font-regular;
                .sub-category-view-masthead__category-headline {
                    margin: 0;
                    font-size: pToR(20);
                    line-height: pToR(28);
                }
                .sub-category-view-masthead__category-title {
                    margin: 16px auto;
                    font-size: pToR(36);
                    line-height: pToR(44);
                    text-align: unset;
                    @include desktop {
                        font-size: pToR(48);
                        line-height: pToR(56);
                    }
                }
                .sub-category-view-masthead__description {
                    font-size: pToR(16);
                    line-height: pToR(24);
                    text-align: unset;
                    @include desktop {
                        max-width: 895px;
                    }
                }
                .sub-category-view-masthead__cta {
                    display: flex;
                    margin-top: pToR(24);
                    .primary-button {
                        font-size: 1.125rem;
                        line-height: 0.8125rem;
                    }
                }
            }
        }
    }
}
