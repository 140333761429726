@import '../../../../../styles/main';
@import 'faqs-accordion.ford';
@import 'faqs-accordion.lincoln';

.faqs-accordion-wrapper {
    .fmc-accordion__button {
        color: unset;
        background-color: $fds-color-lt-gray;
        &-title,
        &-expand {
            font-size: 1.17rem;
            line-height: 26px;
            text-transform: uppercase;
        }
    }
    .fmc-accordion__content {
        padding-top: unset;
        .fmc-type--body1 {
            font-size: 1rem;
            letter-spacing: 1px;
            line-height: 26px;
            color: unset;
            ol,
            ul {
                margin: 0 0 0 16px;
            }
            p {
                font-size: unset;
            }
        }
    }
}
