.brand-ford {
    .horizontal-card {
        display: flex;
        flex-direction: column;
        position: relative;
        justify-content: center;
        width: 100%;
        max-width: 520px;
        font-family: $f1-font-regular;
        font-size: 1rem;
        margin: 0 auto;
        line-height: 26px;
        color: $fds-color-dk-gray;
        text-align: left;
        background-color: #ffffff;
        box-shadow: 0 20px 20px 0 rgba(0, 0, 0, 0.1),
            0 10px 10px 0 rgba(0, 0, 0, 0.1);

        @include desktop {
            flex-direction: row;
            max-width: 1242px;
        }

        .horizontal-card__image {
            width: 100%;
            height: 264px;
            background-size: cover;
            background-position: center center;
            background-origin: border-box;
            @include desktop {
                height: auto;
                width: 50%;
            }
        }

        .horizontal-card__content-wrapper {
            padding: 0 32px;
            width: 100%;
            min-height: 441px;
            @include tablet {
                min-height: 341px;
            }
            @include desktop {
                width: 50%;
            }
            @include xl-desktop {
                padding: 0 160px 0 32px;
            }
            .horizontal-card__title {
                font-size: 1rem;
                text-transform: uppercase;
                margin-top: 28px;
                margin-bottom: 8px;
                text-align: left;
                line-height: 1.5rem;
            }
            .horizontal-card__divider {
                box-shadow: unset;
            }
            .horizontal-card__subtitle {
                margin: 16px 0 0 0;
                font-size: 34px;
                font-family: $f1-font-light;
                line-height: 44px;
                text-transform: uppercase;
                text-align: left;
            }

            .horizontal-card__body {
                font-family: $f1-font-regular;
                margin: 16px 0;
            }

            .horizontal-card__primary-button {
                display: inline-flex;
                margin: 60px 0 36px 0;
                @include desktop {
                    margin: 60px 0 36px 0;
                }
            }
        }
    }

    .category-5050-carousel + .category-5050-carousel__controls {
        margin: 0 0 0 0;
    }
}
