.brand-ford {
    .quiet-card {
        flex: 1;
        font-family: $f1-font-light;
        color: $dropdown-text;
        max-width: 400px;
        text-align: center;
        padding-bottom: 40px;
        position: relative;
        margin-bottom: 80px;
        @include desktop {
            margin: 0 20px 80px 0;

            &:nth-of-type(3) {
                margin-right: 0;
            }
        }
        .quiet-card__image {
            min-height: 241px;
            background-size: cover;
            background-position: center center;
            background-origin: border-box;
        }

        .quiet-card__title {
            margin: 0;
            font-size: 1.5rem;
            line-height: 34px;
            text-transform: uppercase;
            padding: 30px 19px 36px 19px;
        }

        .quiet-card__description {
            font-size: 1rem;
            line-height: 24px;
            text-align: left;
            padding-left: 0px;
            @include desktop {
                padding-left: 16px;
            }
        }

        .quiet-card__button-wrapper {
            position: absolute;
            bottom: 0px;
            left: 0px;
            right: 0px;
            .quiet-card__primary-button {
                display: inline-flex;
            }
        }
    }
}
