.brand-lincoln {
    .article {
        display: flex;
        flex-direction: column;
        padding: 1.5rem 0;

        .article-header {
            text-transform: uppercase;
            color: $dropdown-text;
            font-family: $proxima-nova-semi-bold;
            text-align: center;
            justify-content: center;
            @include desktop {
                text-align: left;
                justify-content: left;
            }
        }
        .article-body {
            font-size: 0.9rem;
            display: flex;
            flex-direction: column;
            justify-content: left;
            @include desktop {
                text-align: left;
            }
        }
        .article-list-body {
            list-style-type: none;
        }
        .article-list {
            padding: 0.5rem 0;
        }
        .article-details {
            text-decoration: none;
            color: inherit;
        }
    }
}
