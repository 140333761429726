.brand-ford {
    .category-5050-carousel-container {
        margin: 60px auto;
        touch-action: pan-y;
        width: 100%;
        overflow: hidden;
        @include desktop {
            margin: 100px auto;
        }
        .category-5050-carousel-heading {
            display: none;
        }
        .category-5050-carousel {
            position: relative;
            z-index: 1;

            .category-5050-carousel__cards {
                display: inline-flex;
                align-items: center;
                flex-wrap: nowrap;
                flex-direction: row;
                position: relative;
                padding-bottom: 30px;

                .category-5050-carousel__card-wrapper {
                    width: 100vw;
                    padding: 0 18px;
                    max-width: 1440px;
                }
            }
        }

        .category-5050-carousel__controls {
            margin: 10px auto 0;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            @include tablet {
                margin: 40px auto 0;
            }
            .category-5050-carousel-button {
                margin: 0 20px;
                padding: 1.25rem;
                &:before {
                    border-radius: 3px;
                    background-color: #00095b;
                    box-shadow: 0 20px 20px 0 rgba(0, 0, 0, 0.1),
                        0 10px 10px 0 rgba(0, 0, 0, 0.1);
                }
                &.disabled {
                    &:before {
                        background-color: #6e6e6e;
                    }
                }
            }
        }
    }
}
