.brand-ford {
    .service-and-offers-section,
    .vehicle-health-page-wrapper {
        .horizontal-card {
            margin-top: 30px;
            .horizontal-card__title {
                color: $fds-color-dk-gray;
                font-family: $f1-font-light;
                font-size: 24px;
                line-height: 34px;
                margin-top: 17px;
                text-align: left;
            }
            .horizontal-card__content-wrapper {
                min-height: 290px;
                padding: 0px 16px;
            }
            .horizontal-card__divider {
                display: none;
            }
            .horizontal-card__primary-button {
                margin-top: 5px;
            }
        }
    }
}
