//colors added for lincoln brand site
$brand-primary: #6b767b;
$brand-secondary: #324047;
$brand-border: #f26147;
$brand-accent: #cc422a;
$brand-white: #fff;
$brand-header-border: #979797;
$brand-black: #22292b;
$brand-black: #090a09;
$brand-light-blue: #1700f4;
$brand-scrollbar: #233338;
$brand-scrollbar-thumb: #22292b;
$ghost-grey: #919191;
$white-smoke: #f7f7f7;
$brand-lds-color-overlay: rgba(0, 0, 0, 0.4);
$border-top-gray3: #e0e0e0;

//added for footer
$brand-dark-grey: #233338;
$brand-offwhite: #fff5ee;

$brand-while-smoke: #efefef;
$brand-link-water: #cacdcf;

// lincoln brand site gray colors
$brand-gray1: #f6f7f7;
$brand-gray2: #ecedee;
$brand-gray3: #e0e0e0;
$brand-mild-gray1: #717171;
$brand-mild-gray2: #54565b;
$brand-disabled-on-dark: #9c9c9c;
$brand-color-border-gray: #a3a3a3;

// lincoln brand alerts
$brand-success: #219653;
$brand-attention: #fcded8;
$brand-light-error: #b00020;
$brand-dark-error: #eb5757;
$brand-success-alert: #d6e9df;
$warning-message-text: #d00c1b;

// lincoln brand dropdown colors
$brand-dropdown-button: #00095b;
$brand-dropdown-list: #807e7f;
$brand-dropdown-selected: #6199f8;
$brand-medium-carmine: #b44633;
