.brand-lincoln {
    .category-5050-carousel-container {
        margin: 60px auto;
        touch-action: pan-y;
        width: 100%;
        @include desktop {
            margin: 100px auto;
        }
        .category-5050-carousel-heading {
            display: none;
        }
        .category-5050-carousel {
            overflow: hidden;

            .category-5050-carousel__cards {
                display: inline-flex;
                align-items: center;
                flex-wrap: nowrap;
                flex-direction: row;
                position: relative;
                padding-bottom: 30px;

                .category-5050-carousel__card-wrapper {
                    width: 100vw;
                    padding: 0 18px;
                }
            }
        }

        .category-5050-carousel__controls {
            margin: 10px auto 0;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            @include tablet {
                margin: 40px auto 0;
            }
            .category-5050-carousel__indicator {
                font-family: $proxima-nova-regular;
                font-size: 1.125rem;
                line-height: 22px;
                color: $brand-secondary;
            }
            .category-5050-carousel-button {
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0 20px;
                padding: 12px;
                width: 32px;
                height: 32px;
                border-radius: 50%;
                background-color: transparent;
                border: 1px solid;
                color: $brand-border;
                &:before {
                    box-shadow: 0 20px 20px 0 rgba(0, 0, 0, 0.1),
                        0 10px 10px 0 rgba(0, 0, 0, 0.1);
                }
                &.disabled {
                    background-color: transparent;
                    color: $ghost-grey;
                    &:before {
                        background-color: $ghost-grey;
                    }
                }
                .fds-chevron {
                    &.left {
                        padding: 1px 1px 0 0;
                    }

                    &.right {
                        padding: 0 1px 0 0;
                    }
                }
            }
        }
    }
}
