.brand-ford {
    .preimagine-category-masthead-container {
        margin-bottom: pToR(64);
        min-width: pToR(320);
        max-width: pToR(991);
        @include desktop {
            margin-bottom: pToR(94);
            min-width: pToR(992);
            max-width: unset;
        }
        color: $dyf-color-darkgrey;
        font-family: $f1-font-regular;
        font-weight: 400;
        line-height: pToR(48);
        .preimagine-category-masthead {
            position: relative;
            min-height: pToR(320);
            width: 100%;
            margin: 0;
            height: auto;
            background-size: cover;
            background-position: center center;
            .preimagine-category-masthead__category-headline {
                position: absolute;
                margin: unset;
                bottom: 0px;
                left: 0px;
                color: $white;
                font-family: $f1-font-regular;
                font-weight: inherit;
                font-size: pToR(40);
                line-height: pToR(48);
                padding: 0 pToR(16) pToR(16) pToR(16);
                @include desktop {
                    font-size: pToR(48);
                    line-height: pToR(56);
                    padding: 0 pToR(56) pToR(32) pToR(56);
                }
            }
        }
        .preimagine-category-masthead__wrapper {
            margin-top: pToR(24);
            padding: 0 pToR(16);
            display: flex;
            flex-direction: column;
            color: $fm-text-grey;
            text-align: left;
            @include desktop {
                margin-top: pToR(48);
                padding: 0 pToR(56);
            }
            .preimagine-category-masthead__category-title {
                margin: unset;
                font-size: pToR(28);
                line-height: pToR(36);
                font-weight: inherit;
                text-align: left;
                @include desktop {
                    font-size: pToR(32);
                    line-height: pToR(40);
                }
            }
            .preimagine-category-masthead__description {
                margin: pToR(16) 0 pToR(24) 0;
                font-size: pToR(16);
                line-height: pToR(24);
                @include desktop {
                    max-width: 75%;
                }
            }
            .preimagine-category-masthead__cta {
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
            }
            &.preimagine-cx-category-masthead {
                min-height: 450px;
                @include tablet {
                    min-height: 350px;
                }
                @include l-desktop {
                    min-height: 500px;
                }
                .preimagine-category-masthead__wrapper {
                    @include l-desktop {
                        padding: 100px 0;
                    }
                    .preimagine-category-masthead__category-title {
                        text-transform: none;
                        font-family: $f1-font-light;
                    }
                }
            }
            .modal-text-center {
                .qr-code-modal-header {
                    color: $text-gray;
                    font-family: $f1-font-light;
                    font-size: 1.5rem;
                    line-height: 2.125rem;
                    margin: 18px 0;
                }
                .qr-code-modal-description {
                    color: $text-gray;
                    font-family: $f1-font-regular;
                    font-size: 1rem;
                    line-height: 1.625rem;
                    margin: 0px 25px;
                }
                .primary-button {
                    margin: auto;
                    margin-top: 18px;
                }
            }
            .preimagine-cx-category-masthead__logo {
                display: flex;
                text-align: center;
                height: 142px;
                padding-top: 46px;
                background-color: $fds-color-white;
                .logo {
                    width: 50%;
                    .logo-left {
                        transform: scale(0.5);
                        @include tablet {
                            float: right;
                            padding-right: 145px;
                            transform: unset;
                        }
                    }
                    .logo-right {
                        transform: scale(0.5);
                        @include tablet {
                            float: left;
                            padding-left: 145px;
                            transform: unset;
                        }
                    }
                }
                .line {
                    position: relative;
                    top: -15px;
                    box-sizing: border-box;
                    height: 85px;
                    width: 1px;
                    border: 1px solid $border-grey;
                }
            }
        }
    }
}
