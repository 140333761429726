@import '../../../../../../styles/main';
.brand-ford .preimagine-5050-banner {
    background: $banner-lightgrey-background;
    .fm-card-text {
        margin: auto;
        width: 100%;
        padding: 40px 16px;
        @include desktop {
            padding: 24px 0;
            width: 35%;
        }
        h2,
        h3,
        div {
            padding: 0;
            margin-bottom: 16px;
        }
        .fm-card__title {
            font-size: 20px;
        }
        .fm-card__headline {
            font-size: 40px;
        }
        .fm-card__description {
            padding: unset;
        }
        .fm-card__button__container {
            padding-top: 8px;
            margin-bottom: 0;
        }
    }
    .image-display {
        height: unset;
        .display-card-image {
            border-radius: unset;
            height: 100%;
            min-height: 336px;
        }
    }
}
