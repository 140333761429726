@import '../../../../../../styles/main';
.brand-ford .preimagine-quiet-cards,
.brand-lincoln .preimagine-quiet-cards {
    margin: 64px 0;
    padding: 0 16px;
    width: 100%;
    max-width: 991px;
    box-sizing: border-box;
    @include desktop {
        margin: 96px 0;
        padding: 0 56px;
        max-width: 1440px;
    }
    .preimagine-quiet-card {
        margin-bottom: 64px;
        .image-display--right .display-card-image-wrapper {
            @include desktop {
                padding-left: 12px;
            }
        }
        .image-display--left .display-card-image-wrapper {
            @include desktop {
                padding-right: 12px;
            }
        }
        .fm-card-text {
            h2,
            h3,
            div {
                padding: 0;
                margin-bottom: 16px;
            }
            .fm-card__title {
                font-size: 20px;
                text-transform: unset;
            }
            .fm-card__description {
                color: inherit;
            }
            .fm-card__button__container {
                margin-bottom: 0;
            }
            @include mobile {
                padding-bottom: 0;
            }
        }
        .image-display {
            height: unset;
        }
    }
}
