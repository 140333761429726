.brand-lincoln {
    .simple-cards-wrapper {
        display: block;

        .simple-cards {
            @include tablet {
                display: grid;
                font-family: $proxima-nova-light;
                grid-gap: 96px;
                padding-top: 89px;
                padding-bottom: 22px;
                grid-template-columns: auto auto;
                justify-content: center;
            }

            @include desktop {
                padding-bottom: 22px;
                grid-template-columns: auto auto auto auto;
                justify-content: center;
            }
        }

        .simple-cards-mobile {
            padding-top: 55px;
            .carousel-wrap {
                margin-left: 6%;
            }
        }

        .simple-cards,
        .simple-cards-mobile {
            .simple-cards-mobile-card {
                width: 300px;
                text-align: center;

                @include tablet {
                    width: 100%;
                    max-width: 300px;
                }
            }

            .simple-cards-mobile-icon {
                height: 40px;
                width: 40px;
                margin-bottom: 18px;
            }

            .simple-cards-mobile-headline {
                @include text(
                    17,
                    2px,
                    27px,
                    $brand-secondary,
                    $proxima-nova-regular
                );
                text-align: center;
                text-transform: uppercase;
                margin-bottom: 18px;
            }

            .simple-cards-mobile-description {
                @include text(
                    16,
                    0,
                    24px,
                    $brand-secondary,
                    $proxima-nova-regular
                );
                text-align: center;
                max-width: 240px;
                margin: 0 auto;
            }
        }
    }
}
