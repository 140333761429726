.brand-ford {
    .card {
        box-shadow: 0 1rem 1rem 0 rgba(black, 0.1),
            0 2rem 2rem 0 rgba(black, 0.1);
        position: relative;
        height: 100%;
        .card-header {
            width: 100%;
            object-fit: cover;
            object-position: center;
            height: 360px;

            opacity: 1;
            display: block;
            transition: 0.5s ease;
            backface-visibility: hidden;
        }
        .card-header-container {
            position: relative;
        }

        .card-header-hover {
            transition: 0.5s ease;
            opacity: 0;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            text-align: center;
            background: rgba(0, 0, 0, 0.7);
            width: 100%;
        }

        .card-header-container:hover .card-header {
            opacity: 1;
        }

        .card-header-container:hover .card-header-hover {
            opacity: 0.8;
        }

        .card-header-container:active .card-header {
            opacity: 1;
        }

        .card-header-container:active .card-header-hover {
            opacity: 0.8;
        }

        .card-header-hover-text {
            color: white;
            width: 100%;
            height: 360px;
            padding: 10% 10%;
            text-align: left;

            .card-header-text-head {
                padding: 15px 0;
            }

            .card-header-text-body {
                p {
                    padding: 15px 0px;
                }
            }
        }

        .card-body {
            padding: 20px 20px 30px 30px;
            font-family: $f1-font-light;
            color: $fds-color-dk-gray;
            .card-title {
                margin-bottom: 16px;
                line-height: pToR(34);
                font-size: pToR(24);
                text-align: left;
            }

            .card-description {
                margin-bottom: 70px;
                font-family: $f1-font-regular;
                font-size: 16px;
                color: #4d4d4d;
                line-height: 26px;
                text-align: left;
            }

            .primary-button {
                margin-top: 20px;
            }
            .button-position {
                position: absolute;
                bottom: 40px;
            }
        }
    }
}
