.brand-ford {
    h2 {
        margin: unset;
        font-weight: unset;
    }
    .preimagine-video-quiet-card {
        display: flex;
        flex-direction: column-reverse;
        align-items: stretch;
        gap: pToR(16);
        padding: 0 pToR(16);
        margin: pToR(64) 0;
        min-width: pToR(320);
        max-width: pToR(991);
        width: 100%;
        font-weight: 400;
        font-family: $f1-font-regular;
        @include desktop {
            flex-direction: row;
            min-width: pToR(992);
            max-width: pToR(1440);
            padding: 0 pToR(56);
            gap: pToR(24);
            margin: pToR(96) 0;
            align-items: stretch;
            @media screen and (max-width: 1210px) {
                align-items: center;
            }
        }
        .preimagine-video-quiet-card__details {
            flex: 1;
            color: $dyf-color-darkgrey;
            padding: 0 pToR(16) pToR(24) pToR(16);
            @include desktop {
                padding: pToR(60) 0 0 0;
                @media screen and (max-width: 1210px) {
                    padding: pToR(0);
                }
            }

            .preimagine-video-quiet-card__title {
                font-size: pToR(36);
                line-height: pToR(44);
                text-align: left;
                @include desktop {
                    font-size: pToR(44);
                    line-height: pToR(52);
                }
            }
            .preimagine-video-quiet-card__description {
                padding: pToR(16) 0;
                font-size: pToR(16);
                line-height: pToR(24);
                text-align: left;
                @include desktop {
                    padding: pToR(32) 0;
                    font-size: pToR(16);
                    line-height: pToR(26);
                }
            }
            .preimagine-video-quiet-card__mobile-app {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: pToR(24);
                @media screen and (max-width: 380px) {
                    gap: pToR(0);
                }
                & > * {
                    flex: 1;
                    display: flex;
                    max-width: pToR(124);
                    align-items: center;
                    & > img {
                        display: block;
                        max-width: 100%;
                        height: auto;
                        margin: auto;
                    }
                }
            }
            .preimagine-cta-optional__primary-button {
                margin: pToR(24) 0;
                display: inline-flex;
            }
        }
        .preimagine-video-quiet-card__media {
            flex: 2;
            position: relative;
            height: 100%;
            img {
                width: 100%;
                height: 100%;
                background-size: cover;
                cursor: pointer;
                pointer-events: none;
                object-fit: cover;
                border-radius: pToR(8);
            }
            .cta-optional__primary-button {
                display: inline-flex;
            }
            &.cx-video-quiet-card-main {
                .video-quiet-card-title {
                    font-size: 34px;
                    text-transform: none;
                    line-height: 44px;
                }
            }
        }
    }
}
