.brand-lincoln {
    .faqs-accordion-container {
        margin: 60px auto;
        text-align: center;
        color: $dropdown-text;
        font-family: $proxima-nova-light;
        line-height: 26px;
        width: 100%;
        max-width: 1240px;
        padding: 0 24px;
        @include desktop {
            margin: 100px auto;
            padding: 0;
        }

        .faqs-accordion-title {
            font-family: $proxima-nova-regular;
            margin-bottom: 16px;
            font-size: 2rem;
            font-weight: 300;
            letter-spacing: 2px;
            line-height: 2.625rem;
        }
        .faqs-accordion-subtitle {
            margin-bottom: 32px;
            font-family: $proxima-nova-regular;
            font-size: 1rem;
            letter-spacing: 1px;
            @include desktop {
                margin-bottom: 60px;
            }
        }
        .faqs-accordion-wrapper {
            text-align: left;
            font-size: 1.125rem;
            font-weight: 300;
            letter-spacing: 2.7px;
            .fds-chevron-wrap {
                margin: 0 12px;
            }
            .accordion-title {
                font-family: $proxima-nova-light;
                color: $brand-primary;
                background-color: unset;
                text-align: left;
                font-size: 1.125rem;
                font-weight: 300;
                line-height: 26px;
                letter-spacing: 2.7px;
                text-transform: uppercase;
                padding: 16px 0 16px 12px;
                @include desktop {
                    padding: 24px 0 24px 12px;
                }
                .accordion-title__header {
                    display: unset;
                }
            }
            .panel {
                padding: 0 34px 16px 34px;
                background-color: unset;
                text-align: left;
                font-family: $proxima-nova-regular;
                font-size: 1rem;
                letter-spacing: 1px;
                line-height: 26px;
                color: $brand-secondary;
            }
            .header-color {
                color: $brand-primary;
            }
            .accordion-title > span:first-child {
                color: $brand-secondary;
                font-family: $proxima-nova-regular;
                font-size: 1.0625rem;
                line-height: 1.6875rem;
                letter-spacing: 2px;
            }
        }
    }
}
