@import '../../../styles/main.scss';

.brand-lincoln {
    .add-vehicle-container {
        display: inline-flex;
        margin: 1rem;
        .modal {
            position: fixed;
            padding: 0;
            margin: 0;
            top: 0;
            max-width: 100vw;
            z-index: 3000;
            background-color: rgba(0, 0, 0, 0.4);
            box-sizing: border-box;
            &.add-vehicle-modal,
            .add-vehicle-smart-tile {
                .modal-content {
                    background-color: $white;
                    position: fixed;
                    top: 0;
                    width: 100%;
                    max-width: 608px;
                    height: unset;
                    max-height: 720px;
                    margin: 0 auto;
                    @include tablet {
                        margin: auto auto 96px;
                        max-width: 100%;
                    }
                    @include md-desktop {
                        position: static;
                        height: 100%;
                        width: 100%;
                        max-height: 688px;
                        max-width: 1024px;
                        margin-top: 32px;
                        border-left: 0;
                        border-right: 0;
                    }
                    .modal-header {
                        margin-bottom: 64px;
                        padding: 12px 16px;
                        text-align: center;
                        .modal-close {
                            margin-top: 0;
                            border: none;
                            font-size: 1.75rem;
                            font-weight: 700;
                            .infoIcon {
                                margin-left: 5px;
                                font-weight: bold;
                                font-size: medium;
                            }
                        }
                    }

                    .modal-body {
                        margin: 96px 0 0 0;
                        padding: 0 40px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        @include tablet {
                            margin-top: 32px;
                            padding: 2px 16px;
                        }
                        .text-center {
                            padding: 0;
                            background-color: $white;
                            @include tablet {
                                max-width: 608px;
                            }
                            .add-vehicle-header {
                                color: $primary-graphite;
                                font-family: $proxima-nova-light;
                                font-weight: 300;
                                font-size: 1.5rem;
                                letter-spacing: 2.5px;
                                line-height: 2rem;
                                margin: 18px 0;
                            }
                            .add-vehicle-vin-header {
                                display: flex;
                                flex-direction: row;
                                justify-content: center;
                                text-transform: uppercase;
                                color: $primary-graphite;
                                font-family: $proxima-nova-light;
                                font-size: 20px;
                                font-weight: 300;
                                letter-spacing: 3px;
                                line-height: 30px;
                                text-align: center;
                                padding: 25px 0;

                                .tooltip {
                                    top: 3px;

                                    .tooltip__infoIcon {
                                        &.dark {
                                            font-size: 16px;
                                        }
                                    }
                                }
                            }
                            .add-vehicle-description {
                                margin-bottom: 24px;
                                text-align: center;
                                color: $primary-graphite;
                                font-family: $proxima-nova-regular;
                                font-size: 1.125rem;
                                line-height: 1.625rem;
                            }

                            .user-input-container {
                                padding: 0;
                                margin: 32px 0;
                                max-width: 100%;
                                font-family: $proxima-nova-regular;
                                text-align: left;

                                .user-input-label {
                                    margin: 0;
                                    padding: 0;
                                    font-size: 0.75rem;
                                    line-height: 1.5rem;
                                    float: left;
                                }

                                .user-input {
                                    font-family: $proxima-nova-regular;
                                    width: 100%;
                                    height: 50px;
                                    box-sizing: border-box;
                                    font-size: pToR(16);
                                    font-weight: normal;
                                    font-stretch: normal;
                                    font-style: normal;
                                    text-align: left;
                                    line-height: pToR(26);
                                    letter-spacing: 1px;
                                    border: 1px solid #6e6e6e;
                                    border-radius: 3px;
                                    background-color: $white;
                                    margin-top: 10px;
                                    padding: 0 16px;
                                    &:focus-visible {
                                        border: 1px solid $primary-graphite;
                                    }
                                    &:hover {
                                        text-underline: $secondary-accent-coral;
                                    }

                                    &.error {
                                        border: 1px solid $severe;
                                    }
                                }
                                .add-vin-errors {
                                    text-align: left;
                                    span {
                                        height: 12px;
                                        color: $severe;
                                        margin: 0 auto;
                                        font-family: $proxima-nova-regular;
                                        font-size: 12px;
                                        letter-spacing: 1px;
                                        line-height: 12px;
                                    }

                                    .icons-text-field-icons-warning {
                                        padding: 0 8px;
                                        display: inline-block;
                                    }
                                }
                                .error-icon.empty {
                                    margin: unset;
                                }
                                input[value=''] + label {
                                    top: -40px;
                                    left: -10px;
                                    font-size: 12px;
                                    display: inline;
                                }
                            }
                        }
                    }

                    .modal-footer {
                        background-color: $white;
                        padding: 32px 16px 96px 16px;
                        margin: 0 auto;
                        display: flex;
                        .modal-footer-buttons {
                            display: flex;
                            flex-direction: row;
                            .text-button {
                                margin-left: 24px;
                                border: 0 transparent;
                                color: $primary-graphite;
                            }
                        }
                        .link-button {
                            padding-left: 2rem;
                            line-height: 13px;
                            font-size: 18px;
                            letter-spacing: 1px;
                            font-family: $proxima-nova-regular;
                        }
                    }
                }
            }
        }
    }
}
