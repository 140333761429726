.brand-lincoln {
    .sub-category-articles-container {
        display: flex;
        flex-direction: column;
        margin: 70px 0;
        font-family: $proxima-nova-regular;

        @include desktop {
            flex-flow: row wrap;
            margin-top: 82px;
            justify-content: space-between;
            // gap: 9%;
            .item-1 {
                flex-basis: 62%;
                order: 1;
            }
            .item-2 {
                flex-basis: 62%;
                order: 3;
            }
            .popular-links-section {
                margin-top: unset;
                order: 2;
                flex-basis: 29%;
            }
        }
    }
}
