.brand-ford {
    .simple-cards-wrapper {
        display: block;

        .simple-cards {
            @include tablet {
                display: grid;
                font-family: $f1-font-light;
                grid-gap: 96px;
                padding-bottom: 22px;
                grid-template-columns: auto auto;
                justify-content: center;
            }

            @include desktop {
                padding-bottom: 22px;
                grid-template-columns: auto auto auto auto;
                justify-content: center;
            }
        }

        .simple-cards-mobile {
            .carousel-wrap {
                margin-left: 6%;
            }
        }

        .simple-cards,
        .simple-cards-mobile {
            .simple-cards-mobile-card {
                min-height: 100%;
                text-align: center;
                @include tablet {
                    width: 100%;
                    max-width: 300px;
                }
            }

            .simple-cards-mobile-icon {
                height: 40px;
                width: 40px;
                margin-bottom: 18px;
            }

            .simple-cards-mobile-headline {
                color: $fds-color-dk-gray;
                font-family: $f1-font-light;
                font-size: pToR(20);
                line-height: pToR(30);
                text-transform: uppercase;
                margin-bottom: 18px;
            }

            .simple-cards-mobile-description {
                color: $fds-color-dk-gray;
                font-family: $f1-font-regular;
                font-size: pToR(16);
                line-height: pToR(26);
                max-width: 240px;
                margin: 0 auto;
            }
        }
        .simple-cards-optional-cta {
            margin-top: 24px;
        }
    }
}
