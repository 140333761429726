.brand-ford {
    .faqs-accordion-container {
        margin: 60px auto;
        text-align: center;
        color: $dropdown-text;
        font-family: $f1-font-light;
        line-height: 26px;
        width: 100%;
        max-width: 1352px;
        padding: 0 14px;
        @include desktop {
            max-width: 1352px;
            padding: 0 24px;
        }

        .faqs-accordion-title {
            margin-bottom: 8px;
            font-size: pToR(34);
            line-height: 44px;
            text-align: center;
        }
        .faqs-accordion-subtitle {
            margin-bottom: 32px;
            font-family: $f1-font-regular;
            font-size: 1rem;
            @include desktop {
                margin-bottom: 60px;
            }
        }
        .faqs-accordion-wrapper {
            text-align: left;
            font-size: 1.125rem;
            .fds-chevron-wrap {
                margin: 0 12px;
            }

            .accordion-section li {
                margin-left: 16px;
            }
            .accordion-title {
                font-family: $f1-font-light;
                color: $dropdown-text;
                background-color: unset;
                text-align: left;
                font-size: 1.125rem;
                line-height: 26px;
                text-transform: uppercase;
                padding: 16px 0 16px 0px;
                @include desktop {
                    padding: 24px 0 24px 0px;
                    line-height: 30px;
                }
                .accordion-title__header {
                    display: unset;
                }
            }

            .panel {
                padding: 0 34px 16px 0px;
                background-color: unset;
                text-align: left;
                font-family: $f1-font-regular;
                font-size: 1rem;
                line-height: 26px;
            }
            .header-color {
                color: $dropdown-text;
            }
        }
    }
    .preimagine-faqs-accordion-container {
        .preimagine-faqs-accordion-title {
            color: $fm-text-grey;
            font-family: $f1-font-regular;
            font-size: 54px;
            line-height: 64px;
            margin-left: 16px;
            @include desktop {
                margin-left: 56px;
            }
        }
        .preimagine-faqs-accordion-subtitle {
            color: $fm-text-grey;
            font-family: $f1-font-regular;
            font-size: 16px;
            line-height: 24px;
            margin-left: 16px;
            @include desktop {
                margin-left: 56px;
            }
        }
        .preimagine-faqs-accordion-wrapper {
            .fm-accordion {
                margin-top: 24px;
            }
        }
    }
}
