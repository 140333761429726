.brand-ford {
    .category-view-masthead-container {
        .category-view-masthead {
            margin: 0;
            padding: 0;
            text-align: center;
            color: $white;
            background-size: cover;
            background-position: center center;
            .category-view-masthead__wrapper {
                margin: 0 auto;
                max-width: 350px;
                max-height: 450px;
                padding: 64px 0;
                font-family: $f1-font-light;
                @include tablet {
                    max-width: 700px;
                    padding: 88px 0 80px 0;
                }
                .category-view-masthead__category-headline {
                    margin: 0;
                    text-transform: uppercase;
                    font-size: 1rem;
                    line-height: 1.625rem;
                    font-family: $f1-font-regular;
                    color: $white;
                }
                .category-view-masthead__category-title {
                    margin: 8px auto;
                    text-transform: uppercase;
                    font-size: pToR(34);
                    line-height: pToR(44);
                    color: $white;

                    @include desktop {
                        font-size: pToR(48);
                        line-height: pToR(58);
                    }
                }
                .category-view-masthead__description {
                    padding: 0 32px;
                    font-family: $f1-font-regular;
                    font-size: 1rem;
                    line-height: 1.625rem;
                    @include tablet {
                        padding: 0 134px;
                    }
                    @include desktop {
                        padding: 0 88px;
                    }
                }
                .category-view-masthead__cta {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-top: 2rem;
                    .primary-button {
                        font-size: 1.125rem;
                        line-height: 0.8125rem;
                    }
                }
            }

            &.cx-category-view-masthead {
                min-height: 450px;
                @include tablet {
                    min-height: 350px;
                }
                @include l-desktop {
                    min-height: 500px;
                }
                .category-view-masthead__wrapper {
                    @include l-desktop {
                        padding: 100px 0;
                    }
                    .category-view-masthead__category-title {
                        text-transform: none;
                        font-family: $f1-font-light;
                    }
                }
            }
        }
        .category-view-masthead__logo {
            display: flex;
            text-align: center;
            height: 142px;
            padding-top: 46px;
            background-color: $fds-color-white;
            .logo {
                width: 50%;
                .logo-left {
                    transform: scale(0.5);
                    @include tablet {
                        float: right;
                        padding-right: 145px;
                        transform: unset;
                    }
                }
                .logo-right {
                    transform: scale(0.5);
                    @include tablet {
                        float: left;
                        padding-left: 145px;
                        transform: unset;
                    }
                }
            }
            .line {
                position: relative;
                top: -15px;
                box-sizing: border-box;
                height: 85px;
                width: 1px;
                border: 1px solid $border-grey;
            }
        }
        .modal-text-center {
            .qr-code-modal-header {
                color: $text-gray;
                font-family: $f1-font-light;
                font-size: 1.5rem;
                line-height: 2.125rem;
                margin: 18px 0;
            }
            .qr-code-modal-description {
                color: $text-gray;
                font-family: $f1-font-regular;
                font-size: 1rem;
                line-height: 1.625rem;
                margin: 0px 25px;
            }
            .primary-button {
                margin: auto;
                margin-top: 18px;
            }
        }
    }
}
