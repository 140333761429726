.brand-lincoln {
    .category-view-masthead-container {
        .category-view-masthead {
            margin: 0;
            padding: 0;
            text-align: center;
            background-size: cover;
            background-position: center center;
            font-family: $proxima-nova-regular;
            color: $white;
            .category-view-masthead__wrapper {
                margin: 0 auto;
                max-width: 350px;
                padding: 112px 0 156px 0;
                letter-spacing: 1px;
                @include tablet {
                    max-width: 700px;
                    padding: 60px 0 80px 0;
                }
                .category-view-masthead__category-headline {
                    margin: 0;
                    font-size: 1rem;
                    line-height: 1.625rem;
                    letter-spacing: 2px;
                    color: $white;
                }
                .category-view-masthead__category-title {
                    font-family: $lincoln-millerb-black;
                    margin-top: 24px;
                    margin-bottom: 24px;
                    padding: 0;
                    font-weight: 900;
                    letter-spacing: 0;
                    font-size: pToR(40);
                    line-height: pToR(48);
                    color: $white;
                    @include desktop {
                        line-height: pToR(70);
                        font-size: pToR(60);
                    }
                }
                .category-view-masthead__description {
                    padding: 0 32px;
                    font-size: pToR(18);
                    line-height: pToR(26);
                    font-family: $proxima-nova-regular;
                    letter-spacing: 0;
                    @include tablet {
                        padding: 0 48px;
                    }
                }
                .category-view-masthead__cta {
                    font-size: 1.125rem;
                    line-height: 0.8125rem;
                    margin-top: 36px;
                    display: inline-block;
                }
            }
        }
        .category-view-masthead__logo {
            display: flex;
            text-align: center;
            height: 142px;
            padding-top: 46px;
            background-color: $fds-color-white;
            .logo {
                width: 50%;
                .logo-left {
                    transform: scale(0.5);
                    @include tablet {
                        float: right;
                        padding-right: 145px;
                        transform: unset;
                    }
                }
                .logo-right {
                    transform: scale(0.5);
                    @include tablet {
                        float: left;
                        padding-left: 145px;
                        transform: unset;
                    }
                }
            }
            .line {
                position: relative;
                top: -15px;
                box-sizing: border-box;
                height: 85px;
                width: 1px;
                border: 1px solid $border-grey;
            }
        }
    }
}
