.brand-lincoln {
    .card {
        box-shadow: 0 1rem 1rem 0 rgba(black, 0.1),
            0 2rem 2rem 0 rgba(black, 0.1);
        position: relative;
        height: 100%;
        .card-header {
            width: 100%;
            object-fit: cover;
            object-position: center;
            height: 360px;
        }
        .card-body {
            padding: 20px 50px 30px 30px;
            color: $text-gray;
            .card-title {
                font-family: $proxima-nova-semi-bold;
                letter-spacing: 3px;
                font-size: pToR(24);
                margin-bottom: 16px;
                line-height: pToR(34);
                font-weight: 300;
                color: $primary-graphite;
            }

            .card-description {
                margin-bottom: 70px;
                font-family: $proxima-nova-regular;
                font-size: pToR(18);
                letter-spacing: 0;
                color: $primary-graphite;
                line-height: pToR(26);
            }
            .primary-button {
                font-family: $proxima-nova-regular;
                color: #ffffff;
                border: none;
                padding: 12px 48px 12px 49px;
                height: 50px;
                width: 130px;
                margin-top: 20px;
                border-bottom: 2px solid transparent;
                &:focus {
                    outline: 1px solid $brand-secondary;
                }
                &:hover {
                    border-bottom: 2px solid $brand-border;
                }

                .button-label {
                    margin-right: 8px;
                    font-size: pToR(16);
                    height: 20px;
                    letter-spacing: 0;
                    line-height: pToR(20);
                }
                .fds-chevron-wrap {
                    display: block;
                    height: 19px;
                }
                .fds-dot {
                    color: $dark-blue;
                }
            }
            .button-position {
                position: absolute;
                bottom: 40px;
            }
        }
    }
}
