.brand-lincoln {
    .category-quiet-cards-trio {
        margin: 60px auto;
        padding: 0 24px;
        max-width: 1288px;
        box-sizing: border-box;
        font-family: $proxima-nova-regular;
        color: $brand-primary;
        @include desktop {
            margin: 100px auto;
        }
        .category-quiet-cards-heading {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            color: $brand-secondary;

            .secondary-button > a {
                padding-right: 0;
                padding-top: 24px;
            }
            .secondary-button-text {
                display: none;

                @include tablet {
                    display: block;
                }
            }
        }
        .category-quiet-cards-trio__title {
            margin: 0;
            font-size: pToR(20);
            font-weight: 300;
            line-height: 30px;
            letter-spacing: pToR(3);
            text-align: left;
            padding-top: 19px;
            padding-bottom: 8px;
        }
        .category-quiet-cards-trio__divider {
            margin: 0 auto;
            box-shadow: unset;
        }
        .category-quiet-cards {
            margin: 30px 0;
            display: flex;
            flex-direction: column;
            @include tablet {
                flex-direction: column;
                align-items: center;
            }
            @include desktop {
                flex-direction: row;
                align-items: stretch;
            }
            .quiet-card {
                @include tablet {
                    width: 100%;
                }
            }
        }
    }
}
