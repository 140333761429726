.brand-lincoln {
    .hero-article {
        .asset-container {
            min-height: 213px;
            min-width: 278px;
            width: auto;
            @include tablet {
                height: 462px;
                max-width: 819px;
            }
            .video-js {
                width: 100%;
                @include tablet {
                    min-height: 462px;
                }
                min-height: 213px;

                .vjs-big-play-button > .vjs-icon-placeholder::before {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    z-index: 10;
                    background: transparent
                        url('../../../../../assets/play.png') center no-repeat;
                    background-size: 100px;
                }
            }
            img {
                max-width: 100%;
            }
        }
        .info-text-container {
            line-height: 34px;
            margin: 30px 0;
            font-family: $proxima-nova-regular;
            color: $brand-secondary;
            text-align: left;
            counter-reset: counter;
            > h1,
            h2,
            h3 {
                font-family: $proxima-nova-light;
                font-size: 24px;
                font-weight: 300;
                letter-spacing: 3px;
                line-height: 34px;
            }

            > p {
                font-size: 16px;
                letter-spacing: 0px;
                line-height: 26px;
                margin: 16px 0;
            }

            p > sup {
                font-size: 0.6875rem;
                line-height: 1px;
                letter-spacing: 0px;
                margin: 0;
            }

            > ul,
            ol {
                margin-left: 33.63px;
                ::marker {
                    color: $brand-border;
                    font-weight: bold;
                    font-family: $proxima-nova-bold;
                    letter-spacing: 2px;
                }
            }

            > ol {
                list-style-type: decimal-leading-zero;
            }
            li {
                margin: 12px 0;
                line-height: 24px;
            }

            @include desktop {
                margin: 39px 0;
            }
        }
    }
}
