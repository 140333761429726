@import '../../../../../styles/main';
.brand-ford .category-quiet-cards,
.brand-lincoln .category-quiet-cards {
    margin: 60px auto;
    padding: 0 24px;
    width: 100%;
    max-width: 1288px;
    box-sizing: border-box;
    @include desktop {
        margin: 100px auto;
    }

    .primary-button {
        display: inline-flex;
    }
}
