.brand-lincoln {
    .breadcrumbs-reimagine {
        .breadcrumbs {
            font-family: $proxima-nova-light;
            color: $white;
            font-size: 1rem;
            padding: 0.5rem 0.5rem 0.5rem 0;

            .breadcrumbs-content {
                font-family: $proxima-nova-regular;
                max-width: $max-content-width;
                padding: 0 1rem 0 1rem;
                letter-spacing: 0;
                color: $brand-mild-gray1;
                line-height: 40px;
                @include mobile {
                    padding: 0 1rem 0 0;
                }
                @include desktop {
                    margin-left: 30px;
                }

                .back-slash {
                    letter-spacing: 0;
                    margin: 0 0.5rem;
                }

                .breadcrumb-link {
                    color: $brand-secondary;
                    letter-spacing: 0;
                    text-decoration: none;
                }

                .breadcrumb-link:hover {
                    font-family: $proxima-nova-semi-bold;
                    color: $primary-graphite;
                    text-decoration: underline;
                    text-decoration-color: $brand-border;
                    text-decoration-thickness: 2px;
                    text-underline-offset: 4px;
                }

                .recalls-breadcrumb-link {
                    color: $brand-secondary;
                    letter-spacing: 0;
                    text-decoration: none;
                    font-family: $proxima-nova-regular;
                    font-size: pToR(16);
                    line-height: pToR(24);
                    text-transform: capitalize;

                    &:hover {
                        text-decoration: underline 2px;
                        text-decoration-color: $brand-border;
                        text-underline-offset: pToR(2);
                    }
                }

                .beta-superscript {
                    font-style: italic;
                }
            }

            &.syndicate-header {
                .breadcrumbs-content {
                    margin: 0 auto;
                    @include desktop {
                        margin-left: 80px;
                    }
                }
            }
        }

        .breadcrumbs-common {
            background-color: $fds-color-lt-gray;
            font-family: $proxima-nova-regular;

            .breadcrumbs-content {
                font-family: $proxima-nova-regular;
                max-width: $max-content-width;
                margin: 0 auto;
                letter-spacing: 0;
                color: $brand-mild-gray1;
                @include desktop {
                    margin-left: 30px;
                }

                .breadcrumb-link {
                    color: $brand-secondary;
                    letter-spacing: 0;
                    text-decoration: none;
                    font-family: $proxima-nova-regular;
                    font-size: 16px;
                    line-height: 24px;
                    text-transform: capitalize;
                }

                .breadcrumb-link:hover {
                    font-family: $proxima-nova-semi-bold;
                    color: $primary-graphite;
                    text-decoration: underline;
                    text-decoration-color: $brand-border;
                }

                .current-page {
                    font-size: 16px;
                    line-height: 24px;
                    color: $brand-mild-gray2;
                    text-transform: capitalize;
                    letter-spacing: 0;
                }

                .back-slash {
                    color: $brand-mild-gray1;
                    letter-spacing: 0;
                    margin: 0 0.5rem;
                }
            }

            &.syndicate-header {
                .breadcrumbs-content {
                    margin: 0 auto;
                    @include desktop {
                        margin-left: 30px;
                    }
                    @include fds-large {
                        margin-left: 80px;
                    }
                }
            }
        }

        .support-fds-wrapper {
            .breadcrumbs-wrapper {
                .breadcrumbs {
                    @include desktop {
                        margin-left: 90px;
                    }
                }
            }
        }
    }
}
