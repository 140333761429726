.brand-ford {
    .breadcrumbs {
        font-family: $f1-font-regular;
        color: $fds-color-dk-gray;
        background-color: $fds-color-surface;
        font-size: pToR(16);
        padding: 1rem 1rem 1rem;

        @include mobile {
            line-height: 40px;
        }
        @include desktop {
            line-height: pToR(26);
        }

        .breadcrumbs-content {
            max-width: $max-content-width;
            margin: 0 auto;
            @include desktop {
                margin-left: 84px;
            }

            .back-slash {
                margin: 0 0.25rem;
            }

            .breadcrumb-link {
                color: $breadcrumb-blue;
                text-decoration: none;
            }

            .recalls-breadcrumb-link {
                color: $breadcrumb-blue;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }

            .breadcrumb-link:hover {
                color: $grabber-blue;
                text-decoration: underline;
            }

            .beta-superscript {
                font-style: italic;
            }
        }

        &.syndicate-header {
            .breadcrumbs-content {
                margin: 0 auto;
                @include desktop {
                    margin-left: 84px;
                }
            }
        }
    }

    .breadcrumbs-common {
        background-color: $fds-color-lt-gray;

        .breadcrumbs-content {
            line-height: 24px;

            .breadcrumb-link {
                text-decoration: none;
                color: $breadcrumb-blue;
                font-family: $f1-font-regular;
                font-size: 16px;
                line-height: 24px;
                text-transform: capitalize;

                @include mobile {
                    line-height: 40px;
                }
            }

            .current-page {
                font-size: 16px;
                line-height: 24px;
                color: $fds-color-dk-gray;
                text-transform: capitalize;
                font-family: $f1-font-regular;

                @include mobile {
                    line-height: 40px;
                }
            }

            .back-slash {
                margin-left: 5px;
                margin-right: 5px;
                color: $fds-color-dk-gray;
            }
        }

        &.syndicate-header {
            .breadcrumbs-content {
                margin: 0 auto;
                @include desktop {
                    margin-left: 100px;
                    max-height: 56px;
                    height: 100%;
                }
                @include fds-large {
                    margin-left: 100px;
                }
            }
        }
    }

    .eu-header {
        .breadcrumbs {
            .breadcrumbs-content {
                margin-left: 0px;
            }
        }
    }

    .support-fds-wrapper {
        .breadcrumbs-wrapper {
            .breadcrumbs {
                @include desktop {
                    padding-left: 90px;
                }
            }
        }
    }
}
