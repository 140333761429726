.brand-lincoln {
    .language-bar {
        background-color: $brand-white;
        padding: 6px 48px;
        margin-bottom: 60px;
        font-family: $proxima-nova-regular;
        font-size: 1rem;
        line-height: 1.125rem;
        text-align: center;

        @include desktop {
            padding: 12px 96px;
            margin-bottom: 80px;
        }
        .language-bar-list-wrapper {
            margin: 0 auto;
            display: flex;
            @include tablet {
                justify-content: center;
            }
            .language-bar-list {
                align-items: center;
                display: flex;
                flex-direction: row;
                list-style: none;
                overflow: hidden;
                margin: 0 18px;

                li {
                    display: inline-block;
                }
                a:hover {
                    cursor: pointer;
                }
                .language-bar-list-button {
                    font-family: $proxima-nova-light;
                    font-size: 1.25rem;
                    color: $brand-secondary;
                    display: inline-block;
                    margin: 0;
                    padding: 0px 24px;
                    text-align: left;
                    background: transparent;
                    border: none;
                    word-break: keep-all;
                    &:hover {
                        text-decoration: underline;
                        text-underline-offset: 3px;
                        text-decoration-thickness: 1.5px;
                        text-decoration-color: $secondary-accent-coral;
                        cursor: pointer;
                    }
                }
            }
            .language-bar__ellipses {
                position: relative;
                font-size: 1.5rem;
                color: $brand-secondary;
                background: transparent;
                border: none;
                width: 60px;
            }
        }
        .language-bar-model {
            color: $brand-white;
            text-align: left;
            .modal-body > div {
                padding: 48px;
            }
            p:first-child {
                font-weight: bold;
            }
            p {
                font-family: $proxima-nova-regular;
                font-size: 1rem;
                letter-spacing: 1px;
                line-height: 1.625rem;
            }
            ul {
                padding-left: 48px;
            }
            li {
                font-family: $proxima-nova-regular;
                font-size: 1rem;
                letter-spacing: 1px;
                line-height: 1.625rem;
                padding: 6px 0;
            }
            .arabic-text {
                padding-right: 48px;
                text-align: right;
                ul {
                    padding-left: 48px;
                    list-style: none;
                }
            }
        }
    }

    .language-bar-tooltip-list {
        list-style: none;
        .language-bar-list-button-ellipses {
            font-family: $proxima-nova-regular;
            font-size: 1.25rem;
            color: $brand-white;
            display: inline-block;
            margin: 0 auto;
            padding: 8px 0;
            width: 100%;
            text-align: center;
            background: transparent;
            border: none;
            white-space: nowrap;
            word-break: break-all;
            &:hover {
                text-decoration: underline;
                text-underline-offset: 3px;
                text-decoration-thickness: 1.5px;
                text-decoration-color: $secondary-accent-coral;
                cursor: pointer;
            }
        }
    }
    .modal-content {
        text-align: left;
        .modal-body > div {
            padding: 48px;
        }
        ul {
            padding-left: 48px;
        }
        li {
            padding: 12px 0;
        }
    }
    .language-bar__tooltip-dismiss-icon {
        display: block;
        position: relative;
        left: 90%;
    }
}
