.brand-ford {
    .hero-article {
        .asset-container {
            min-height: 213px;
            min-width: 278px;
            width: auto;
            @include tablet {
                height: 462px;
                max-width: 819px;
            }
            .video-js {
                width: 100%;
                @include tablet {
                    min-height: 462px;
                }
                min-height: 213px;

                .vjs-big-play-button > .vjs-icon-placeholder::before {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    z-index: 10;
                    background: transparent
                        url('../../../../../assets/play.png') center no-repeat;
                    background-size: 100px;
                }
            }
            img {
                max-width: 100%;
            }
        }
        .info-text-container {
            line-height: 34px;
            margin: 30px 0;
            font-family: $f1-font-regular;
            color: #4d4d4d;
            text-align: left;

            > h1,
            h2,
            h3 {
                font-family: $f1-font-light;
                font-size: 24px;
                line-height: 34px;
            }

            > p {
                font-size: 16px;
                line-height: 26px;
                margin: 16px 0;
            }

            > ul,
            ol {
                margin-left: 33.63px;
                ::marker {
                    color: $fds-color-primary;
                    font-family: $f1-font-bold;
                }
            }

            > ol {
                list-style-type: decimal-leading-zero;
            }
            @include desktop {
                margin: 39px 0;
            }
        }

        &.cx-hero-article {
            .asset-container {
                margin: 0 auto;
                min-height: auto;

                @include tablet {
                    height: auto;
                }
                > img {
                    pointer-events: none;
                    object-fit: cover;
                }

                .vjs-poster {
                    background-size: cover;
                }
                .media-disclaimer {
                    @include mobile {
                        font-size: 10px;
                        margin-top: 8px;
                    }
                    font-family: $f1-font-regular;
                    color: $fds-color-md-gray;
                    line-height: 18px;
                    font-size: 12px;
                    margin-top: 8px;
                }
            }
            .info-text-container {
                @include mobile {
                    overflow-x: auto;
                }

                > h1,
                h2,
                h3,
                h4,
                h5,
                h6 {
                    font-family: $f1-font-regular;
                    color: $breadcrumb-blue;
                    font-size: revert;
                }

                > blockquote {
                    font-family: $f1-font-regular;
                    color: $breadcrumb-blue;
                    font-size: 24px;
                    padding: 0 4%;
                    text-align: center;
                }

                > table {
                    width: -webkit-fill-available;
                    width: -moz-available;
                    border-collapse: collapse;
                    @include fds-large {
                        margin: 0 10% 0 0;
                    }
                }

                > ol {
                    list-style-type: decimal;
                }

                > ul,
                > ol {
                    -webkit-font-smoothing: auto;
                    -moz-osx-font-smoothing: auto;
                }

                table,
                tr,
                td {
                    border: 1px solid;
                    color: $fds-color-black;
                    font-size: 0.875rem;
                    padding: 0.5625rem 0.625rem;
                    line-height: 1.125rem;
                }

                td {
                    text-align: center;
                }

                table tr:nth-child(even) {
                    background: $eyebrow-grey;
                }
            }
        }
    }
    .hero-article.preimagine {
        .info-text-container {
            > ul,
            ol {
                ::marker {
                    color: $fm-text-grey;
                }
                li {
                    color: $fm-text-grey;
                    a {
                        color: $fm-breadcrumb-blue;
                    }
                }
            }
            > h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
                line-height: 32px;
                color: $fm-text-grey;
                font-family: $f1-font-regular;
                font-weight: 500;
            }
            p {
                line-height: 24px;
                color: $fm-text-grey;
                a {
                    color: $fm-breadcrumb-blue;
                }
            }
        }
    }
}
