@import '../../../../../../styles/main';

.fds-video-content-main {
    .fds-video-content {
        box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1);
        position: relative;
        .video-js {
            width: 100%;
            padding-top: 56.25%;
            .vjs-big-play-button {
                margin: 0;
                position: absolute;
                top: 50%;
                left: 50%;
                -ms-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
                background-color: transparent !important;
                background-image: url('../../../../../../assets/play.png');
                background-repeat: no-repeat;
                background-size: contain;
                border-radius: 0;
                padding: 2px;

                width: 6rem;
                height: 6rem;
                &:hover {
                    width: 7rem;
                    height: 7rem;
                }
                @include tablet {
                    width: 7rem;
                    height: 7rem;
                    &:hover {
                        width: 8rem;
                        height: 8rem;
                    }
                }
                .vjs-icon-placeholder {
                    display: none;
                }
            }
        }
    }
}
