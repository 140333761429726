.brand-ford {
    .video-quiet-card-main {
        margin: 0 auto;
        display: flex;
        flex-direction: column-reverse;
        max-width: 520px;

        @include desktop {
            flex-direction: row;
            margin: 100px auto 40px;
            max-width: 1288px;
            width: 100%;
            padding-right: 20px;
        }

        @media (max-width: 550px) {
            margin: 0 18px;
        }

        .video-quiet-card-content {
            @include desktop {
                margin-right: 103px;
                margin-left: 30px;
                width: 421px;
            }

            @media (max-width: 991px) {
                text-align: center;
            }
        }

        .video-quiet-card-title {
            color: $fds-color-dk-gray;
            font-size: 48px;
            line-height: 58px;
            text-transform: uppercase;
            font-family: $f1-font-light;
            margin-bottom: 15px;
            text-align: left;

            @media (max-width: 991px) {
                margin-bottom: 28px;
                margin-top: 30px;
                text-align: center;
            }
        }

        .video-quiet-card-description {
            color: $fds-color-dk-gray;
            font-family: $f1-font-regular;
            font-size: 16px;
            line-height: 26px;
            text-align: left;
            margin-bottom: 15px;
            @media (max-width: 991px) {
                margin-bottom: 30px;
                text-align: center;
            }
        }

        .mobile-app-cta {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: pToR(24);
            @media screen and (max-width: 380px) {
                gap: pToR(0);
            }
            & > * {
                flex: 1;
                display: flex;
                max-width: pToR(124);
                align-items: center;
                & > img {
                    display: block;
                    max-width: 100%;
                    height: auto;
                    margin: auto;
                }
            }
        }

        .image-or-video {
            @include desktop {
                width: 715px;
            }

            @media (max-width: 991px) {
                margin-top: 60px;
            }

            img {
                box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1);
                width: 100%;
                height: 400px;
                background-size: cover;
                cursor: pointer;
                pointer-events: none;
                object-fit: cover;

                @media (max-width: 425px) {
                    height: 212px;
                }
            }
        }

        .cta-optional__primary-button {
            display: inline-flex;
        }

        &.cx-video-quiet-card-main {
            .video-quiet-card-title {
                font-size: 34px;
                text-transform: none;
                line-height: 44px;
            }
        }
    }

    .video-quiet-card--media-content {
        padding: 0;
        margin: 0;

        .video-quiet-card-content {
            margin-left: 103px;
            margin-right: 30px;
        }
    }
}
