.brand-lincoln {
    .popular-links-section {
        margin-top: 50px;
        max-height: 620px;
        max-width: 317px;
        @include desktop {
            position: sticky;
            top: 20%;
            right: 10%;
            height: 100%;
        }
        .related-categories,
        .popular-in-rewards {
            max-height: 285px;
            &__header {
                @include text(
                    17,
                    2px,
                    27px,
                    $brand-secondary,
                    $proxima-nova-regular
                );
            }
            &__item {
                margin-top: 20px;
                a {
                    @include text(
                        16,
                        0px,
                        20px,
                        $brand-secondary,
                        $proxima-nova-semi-bold
                    );
                    text-decoration: none;
                }
            }
        }
        .popular-in-rewards {
            margin-top: 50px;
        }
        hr {
            box-shadow: none;
        }
        .popular-in-rewards__item a {
            &:hover {
                text-decoration: underline;
                text-decoration-color: $brand-border;
                cursor: pointer;
                text-decoration-thickness: 2px;
            }
        }
        .related-categories__item a {
            &:hover {
                text-decoration: underline;
                text-decoration-color: $brand-border;
                cursor: pointer;
                text-decoration-thickness: 2px;
            }
        }
    }
}
