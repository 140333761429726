.brand-lincoln {
    .video-quiet-card-main {
        margin: 0 auto;
        display: flex;
        flex-direction: column-reverse;
        max-width: 520px;

        @include desktop {
            flex-direction: row;
            margin: 100px auto 40px;
            max-width: 1288px;
            width: 100%;
            padding-right: 20px;
        }

        @media (max-width: 550px) {
            margin: 0 18px;
        }

        .video-quiet-card-content {
            @include desktop {
                margin-right: 103px;
                margin-left: 30px;
                width: 421px;
            }

            @media (max-width: 991px) {
                text-align: center;
            }
        }

        .video-quiet-card-title {
            color: $brand-secondary;
            font-size: pToR(42);
            font-weight: 300;
            letter-spacing: 2.63px;
            line-height: pToR(52);
            text-transform: uppercase;
            font-family: $proxima-nova-regular;
            margin-bottom: 15px;
            text-align: left;

            @media (max-width: 991px) {
                margin-bottom: 28px;
                margin-top: 30px;
                text-align: center;
            }
        }

        .video-quiet-card-description {
            color: $brand-secondary;
            font-family: $proxima-nova-regular;
            font-size: pToR(18);
            letter-spacing: 0;
            line-height: pToR(24);
            text-align: left;
            margin-bottom: 15px;
            @media (max-width: 991px) {
                margin-bottom: 30px;
                text-align: center;
            }
        }

        .mobile-app-cta {
            width: max-content;
            margin: 0 auto 30px auto;
            @include desktop {
                padding-top: 10px;
                margin: 0 0 20px 0;
            }
        }

        .apple-app-cta {
            @media (min-width: 366px) {
                margin-right: 20px;
            }
            @media (max-width: 365px) {
                margin-bottom: 20px;
                display: block;
                margin-left: auto;
                margin-right: auto;
            }
        }

        .google-app-cta {
            padding-bottom: 1px;
        }

        .image-or-video {
            @include desktop {
                width: 715px;
            }

            @media (max-width: 991px) {
                margin-top: 60px;
            }

            img {
                box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1);
                width: 100%;
                height: 400px;
                background-size: cover;
                cursor: pointer;
                pointer-events: none;
                object-fit: cover;

                @media (max-width: 425px) {
                    height: 212px;
                }
            }
        }

        .cta-optional__primary-button {
            display: inline-flex;
        }
    }
}
