.brand-ford {
    .breadcrumbs-reimagine {
        .breadcrumbs {
            font-family: $f1-font-regular;
            color: $fds-color-dk-gray;
            background-color: $fds-color-surface;
            font-size: pToR(16);
            padding: 1rem 1rem 1rem;

            @include mobile {
                line-height: 40px;
                width: 100% !important;
            }

            @include desktop {
                line-height: pToR(26);
            }

            .breadcrumbs-content,
            .breadcrumbs-content-boolean {
                max-width: $max-content-width;
                margin: 0 auto;

                @include mobile {
                    display: inline-flex;
                }
                @include desktop {
                    margin-left: 84px;
                }

                .back-slash {
                    margin: 0 0.25rem;
                    color: $fm-text-grey !important;

                    @include mobile {
                        display: inline-block;
                    }
                }

                .ellipses-icon {
                    padding: 3px 10px 0 10px;
                }

                .breadcrumb-link {
                    color: $fm-breadcrumb-blue !important;
                    text-decoration: none;
                    cursor: pointer;
                    padding-right: 10px;

                    @include mobile {
                        line-height: 40px;
                        max-width: 160px;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                    }
                }
                .breadcrumb-link-dots {
                    text-decoration: none;
                    color: $fm-breadcrumb-blue;
                    font-family: $f1-font-regular;
                    font-size: 16px;
                    line-height: 24px;
                    text-transform: capitalize;

                    @include mobile {
                        line-height: 40px;
                        max-width: 100%;
                        text-overflow: initial;
                        white-space: normal;
                        overflow: unset;
                        padding-right: 10px;
                    }
                }

                .breadcrumb-link-ellipses-expanded {
                    text-decoration: none;
                    color: $fm-breadcrumb-blue;
                    font-family: $f1-font-regular;
                    font-size: 16px;
                    line-height: 24px;
                    text-transform: capitalize;
                    cursor: pointer;
                    padding: 0 10px;

                    @include mobile {
                        max-width: 100%;
                        text-overflow: initial;
                        white-space: normal;
                        overflow: unset;
                    }
                }

                .recalls-breadcrumb-link {
                    color: $breadcrumb-blue;
                    text-decoration: none;

                    &:hover {
                        text-decoration: underline;
                    }
                }

                .breadcrumb-link:hover {
                    color: $grabber-blue;
                    text-decoration: underline;
                }

                .breadcrumb-link-ellipses-expanded:hover {
                    text-decoration: underline;
                }

                .beta-superscript {
                    font-style: italic;
                }
            }
            .breadcrumbs-content-boolean {
                @include mobile {
                    overflow: unset;
                    text-overflow: unset;
                    white-space: initial;
                    color: $fm-text-grey;
                    display: block !important;
                }
            }
            .breadcrumbs-content-ellipsis {
                padding-left: 10px;
                @include mobile {
                    max-width: 160px;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                }
            }
            .breadcrumbs-content-initial {
                @include mobile {
                    max-width: 100%;
                    text-overflow: initial;
                    white-space: normal;
                    overflow: unset;
                    padding-left: 10px;
                    overflow-wrap: anywhere;
                    font-size: 16px;
                    line-height: 24px;
                    color: $fm-text-grey;
                    text-transform: capitalize;
                    font-family: $f1-font-regular;
                }
            }

            &.syndicate-header {
                .breadcrumbs-content {
                    margin: 0 auto;

                    @include desktop {
                        margin-left: 84px;
                    }
                }
            }
        }

        .breadcrumbs-common {
            background-color: $fds-color-lt-gray;

            .breadcrumbs-content {
                line-height: 24px;

                @include mobile {
                    width: 100%;
                }

                .breadcrumb-link {
                    text-decoration: none;
                    color: $fm-breadcrumb-blue;
                    font-family: $f1-font-regular;
                    font-size: 16px;
                    line-height: 24px;
                    text-transform: capitalize;
                }

                .current-page {
                    font-size: 16px;
                    line-height: 24px;
                    color: $fm-text-grey;
                    text-transform: capitalize;
                    font-family: $f1-font-regular;

                    @include mobile {
                        display: inline-block;
                    }
                }

                .back-slash {
                    margin-left: 5px;
                    margin-right: 5px;
                    color: $fm-text-grey;
                }
            }

            &.syndicate-header {
                .breadcrumbs-content {
                    margin: 0 auto;

                    @include desktop {
                        margin-left: 100px;
                        max-height: 56px;
                        height: 100%;
                    }

                    @include fds-large {
                        margin-left: 100px;
                    }
                }
            }
        }

        .eu-header {
            .breadcrumbs {
                .breadcrumbs-content {
                    margin-left: 0px;
                }
            }
        }

        .support-fds-wrapper {
            .breadcrumbs-wrapper {
                .breadcrumbs {
                    @include desktop {
                        padding-left: 90px;
                    }
                }
            }
        }
    }
}
