@import '../../../styles/main.scss';
@import '../../../styles/lincoln/main.scss';
.ccpa {
    text-align: center;
    background-color: $dark-blue;
    padding-top: 20px;
    padding-bottom: 20px;
    font-family: $f1-font-regular;
    color: $white;

    h3 {
        font-size: 42px;
    }
    p {
        margin: 0px;
        font-family: $f1-font-light;
    }
    .description a {
        color: $light-skyblue;
    }
    .ccpa-button {
        margin-bottom: 0;
        .link {
            width: auto;
            background-color: $hover-blue;
            border: 1px solid $dark-blue;
            color: $white;
            padding: 15px;
            font-family: $f1-font-light;
            font-size: 16px;
            text-align: center;
            text-decoration: none;
            display: inline-block;
            margin-top: 1rem;
            white-space: normal;
            line-height: 1.25;
        }
        .link:hover {
            background: $white;
            color: $dark-blue;
        }
    }
}
.lincoln-na-footer {
    .ccpa {
        text-align: center;
        padding-top: 20px;
        padding-bottom: 3rem;
        font-weight: lighter;
        font-family: $proxima-nova-semi-bold;
        color: $white;
        background-color: $brand-secondary;
        h3 {
            font-size: 42px;
            font-weight: lighter;
            font-family: $proxima-nova-regular;
            text-transform: uppercase;
        }
        p {
            margin: 0px;
        }
        .description a {
            color: $white;
            border-bottom: 2px solid $brand-border;
            text-decoration: none;
        }
        .ccpa-button {
            margin-bottom: 0;
            .link {
                width: auto;
                background-color: $brand-while-smoke;
                border: 1px solid $dark-blue;
                color: $brand-secondary;
                padding: 15px;
                font-family: $f1-font-light;
                font-size: 14px;
                text-align: center;
                text-decoration: none;
                display: inline-block;
                margin-top: 1rem;
                white-space: normal;
                line-height: 1.25;
                border-bottom: 2px solid transparent;
                &:hover,
                &:focus {
                    border-bottom-color: $brand-border;
                    transition: ease-in;
                }
            }
        }
    }
}
